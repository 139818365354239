.TextFilter {
  display: inline-block;
  margin: 0 1rem 0 0;
  text-align: left;
  width: 100%;
  input {
    color: #595a5b;
    width: 100%;
  }
}
