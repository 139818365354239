.NumericRange {
  align-items: center;
  margin: 0 1rem 0 0;
  text-align: left;
  width: 100%;
  &__range-inputs {
    display: flex;
  }
  input {
    color: #595a5b;
    max-width: 90px;
    &:first-child {
      margin-right: 1rem;
    }
  }
}
