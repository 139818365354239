.EmptyReport {
  background-color: #fff;
  min-height: 200px;
  text-align: center;
  padding: 50px;
  h3 {
    margin: 0;
  }
}

.FilterForm {
  background-color: #fff;
  padding: 15px;
  text-align: left;
  &__button {
    display: inline-block;
    margin-left: 20px;
  }
  &__textInput {
    border: 1px solid;
    border-color: #ccc;
    border-radius: 3px;
    height: 35px;
    max-width: 200px;
    padding: 1rem;
  }
}

.Columnar {
  &--capitalize {
    text-transform: capitalize;
  }
}

.ColumnarLoader {
  min-height: 50vh;
  background-color: #fff;
  position: relative;
}

.EmptyState {
  background: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e2e2e2;
  p {
    text-align: left;
  }
  svg {
    circle {
      fill: #d3d3d3;
    }
  }
}
