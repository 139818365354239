.RunsListContainer {
  height: 85%;
  overflow: scroll;
  margin-top: 2rem;
  width: 100%;
  padding: 0 1rem;
  background: #fff;

  &__heading {
    margin-bottom: 1rem;
  }
}

.RunsList {
  list-style-type: none;
  padding: 0;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgb(231, 231, 231);
    padding: 1.5rem 0.25rem;
    &:last-child {
      border-bottom: none;
    }
  }
}
