.SubscriptionForm {
  padding: 1rem;
  input {
    font-size: 14px;
  }
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 1.15rem;
    &::before {
      display: inline-block;
      content: '';
      background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20156%20156%22%3E%3Cg%20data-name%3D%22Layer%202%22%3E%3Ccircle%20fill%3D%22%23f0f0f0%22%20cx%3D%2278%22%20cy%3D%2278%22%20r%3D%2278%22%2F%3E%3Cpath%20fill%3D%22%23fff%22%20stroke%3D%22%23808284%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%224%22%20d%3D%22M56.49%2056.37h69.77v45.26H56.49z%22%2F%3E%3Cpath%20fill%3D%22%23fff%22%20stroke%3D%22%23808284%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%224%22%20d%3D%22M126.26%2056.37L91.38%2087.44%2056.49%2056.37h69.77zM56.49%20101.63L79.95%2079M126.12%20101.63L102.67%2079%22%2F%3E%3Cpath%20fill%3D%22none%22%20stroke%3D%22%23808284%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%20stroke-width%3D%224%22%20d%3D%22M47.37%2067.69h-15M47.37%2090.31H30.84M42.27%2079H25.74%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      @media screen and (max-width: 370px) {
        background-image: none;
        content: none;
      }
    }
  }
}

.SubscriptionButton {
  background-color: #e4e5e6;
  border: none;
  &:hover {
    background-color: rgb(202, 202, 202);
  }
}
