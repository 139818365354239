.Spinner {
  border: 2px solid #fefefe;
  border-top: 2px solid #ccc;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  margin: 0 auto;
  animation-name: spin;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
