.LoadingBar {
  display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100%;
  .loadingPulses {
    animation: loadingPulseAnimation 0.5s ease-in-out infinite alternate;
  }
}
@keyframes loadingPulseAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
