@import '~@pwr/tachyons/dist/tachyons.min.css';
@import '~@pwr/brandon-text/css/brandon-text.css';
@import '~@pwr-ui/css/dist/ds.css';
@import '~@pwr-ui/select/dist/pwr-ui-select.css';

body {
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  height: 100vh;
  font-family: 'BrandonText', 'Helvetica Neue', Helvetica, sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input,
optgroup,
select,
textarea,
p,
span,
a {
  font-family: 'BrandonText', 'Helvetica Neue', Helvetica, sans-serif !important;
  text-rendering: optimizeLegibility;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page-container {
  min-width: 50rem;
  max-width: 70rem;
  margin-left: auto;
  margin-right: auto;
}

// Satismeter overrides

.nps-Survey {
  font-family: inherit !important;
  font-size: inherit !important;

  .nps-Title {
    font-size: 1.05rem !important;
  }

  .nps-Dialog {
    border-width: 1px 0 0 !important;
    border-color: #f0f0f0 !important;
    // prettier-ignore
    box-shadow:
      0 0 1px hsla(0, 0%, 7%, 0.3),
      0 10px 25px -4px hsla(0, 0%, 7%, 0.4);

    // we do not use !important on these because they are animated in and out!
    &.nps-Dialog--right {
      right: 10px;
    }

    &.nps-Dialog--top {
      top: 10px;
    }

    &.nps-Dialog--left {
      left: 10px;
    }

    &.nps-Dialog--bottom {
      bottom: 10px;
    }
  }

  .nps-Button--icon {
    background-color: transparent !important;
    color: #007bc3 !important;
  }
}

.show_chartTypeSelector_wrapper {
  display: none !important;
}

.wiggle {
  animation: wiggle 2.5s infinite;
}

.wiggle:hover {
  animation: none;
}
@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  85% {
    transform: rotate(10deg);
  }
  95% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
