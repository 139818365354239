.main {
  padding-left: calc(200px + 4rem);
  padding-bottom: 3rem;

  &__logo {
    animation: App-logo-spin infinite 20s linear;
    height: 10vmin;
  }

  &__header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  &__link {
    color: #61dafb;
  }
}
