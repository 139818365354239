.ColumnDndEditor {
  display: flex;
  flex-direction: column;
  overflow: scroll;

  &__columns {
    align-items: flex-start;
    display: flex;
    height: 50vh;
    overflow: scroll;
  }
  &__column {
    border: 1px solid #dddddd;
    margin: 0.5rem;
    min-height: 100%;
  }
  &__list {
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    width: 300px;
  }
  &__list-header {
    font-size: 16px;
    background-color: #f4f4f4;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 1rem;
  }

  & .ListItem {
    align-items: center;
    background: #fff;
    border: 1px solid #dddddd;
    box-shadow: 0 1px 0 0 #dddddd;
    display: flex;
    margin: 0.6rem 0.6rem 0 0.6rem;
    max-width: 300px;
    min-height: 45px;
    padding: 0.75rem;
    position: relative;
    text-align: left;
    &__content {
      margin: 0 0.75rem;
      max-width: 200px;
    }
    &__button {
      background-position: center;
      background-repeat: no-repeat;
      font-size: 0px;
      height: 40px;
      position: relative;
      width: 40px;
      &--add {
        position: absolute;
        right: 10px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='17' height='17' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' d='M-1-1h582v402H-1z'/%3e%3cg%3e%3cpath d='M-1-1h582v402H-1V-1z' fill='none'/%3e%3cpath fill='%23808284' d='M9.511 9.511l-.124 8.702a.506.506 0 0 1-.512.513l-1.1.016a.478.478 0 0 1-.361-.138.478.478 0 0 1-.138-.36l.123-8.642-8.642.123a.478.478 0 0 1-.361-.138.478.478 0 0 1-.137-.361l.015-1.1a.506.506 0 0 1 .513-.513L7.49 7.49l.124-8.702a.506.506 0 0 1 .512-.513l1.1-.016a.478.478 0 0 1 .36.137.48.48 0 0 1 .139.361L9.602 7.4l8.641-.123a.478.478 0 0 1 .36.137.48.48 0 0 1 .139.361l-.016 1.1a.506.506 0 0 1-.513.513L9.51 9.51l.001.001z' fill-rule='evenodd'/%3e%3c/g%3e%3c/svg%3e");
        &:hover {
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='17' height='17' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill='none' d='M-1-1h582v402H-1z'/%3e%3cg%3e%3cpath d='M-1-1h582v402H-1V-1z' fill='none'/%3e%3cpath fill='%2357595b' d='M9.511 9.511l-.124 8.702a.506.506 0 0 1-.512.513l-1.1.016a.478.478 0 0 1-.361-.138.478.478 0 0 1-.138-.36l.123-8.642-8.642.123a.478.478 0 0 1-.361-.138.478.478 0 0 1-.137-.361l.015-1.1a.506.506 0 0 1 .513-.513L7.49 7.49l.124-8.702a.506.506 0 0 1 .512-.513l1.1-.016a.478.478 0 0 1 .36.137.48.48 0 0 1 .139.361L9.602 7.4l8.641-.123a.478.478 0 0 1 .36.137.48.48 0 0 1 .139.361l-.016 1.1a.506.506 0 0 1-.513.513L9.51 9.51l.001.001z' fill-rule='evenodd'/%3e%3c/g%3e%3c/svg%3e");
        }
      }
      &--remove {
        position: absolute;
        right: 10px;
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='17' height='17' xmlns='http://www.w3.org/2000/svg' fill='%23808284'%3E %3Cpath d='M9.466 8l6.066 6.241a.506.506 0 0 1 0 .725l-.767.789a.478.478 0 0 1-.352.158.478.478 0 0 1-.353-.158L8.037 9.558l-6.024 6.197a.478.478 0 0 1-.353.158.478.478 0 0 1-.352-.158l-.767-.788a.506.506 0 0 1 0-.726L6.608 8 .542 1.759a.506.506 0 0 1 0-.725l.766-.789A.478.478 0 0 1 1.66.087c.133 0 .25.053.353.158l6.024 6.197L14.06.245a.478.478 0 0 1 .352-.158c.133 0 .25.053.353.158l.766.788a.506.506 0 0 1 0 .726L9.466 8z' fill-rule='evenodd'/%3E %3C/svg%3E");

        &:hover {
          background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='17' height='17' xmlns='http://www.w3.org/2000/svg' fill='%2357595b'%3E %3Cpath d='M9.466 8l6.066 6.241a.506.506 0 0 1 0 .725l-.767.789a.478.478 0 0 1-.352.158.478.478 0 0 1-.353-.158L8.037 9.558l-6.024 6.197a.478.478 0 0 1-.353.158.478.478 0 0 1-.352-.158l-.767-.788a.506.506 0 0 1 0-.726L6.608 8 .542 1.759a.506.506 0 0 1 0-.725l.766-.789A.478.478 0 0 1 1.66.087c.133 0 .25.053.353.158l6.024 6.197L14.06.245a.478.478 0 0 1 .352-.158c.133 0 .25.053.353.158l.766.788a.506.506 0 0 1 0 .726L9.466 8z' fill-rule='evenodd'/%3E %3C/svg%3E");
        }
      }
    }
    &--dragging {
      box-shadow: 0 19px 38px rgba(0, 0, 0, 0.15),
        0 15px 12px rgba(65, 50, 50, 0.08);
    }
    &--hidden {
      display: none;
    }
  }
  &__button-panel {
    margin: 0.75rem;
  }
}
