.FormInput {
  margin: 1rem 0;
  width: 100%;
  label {
    display: block;
    font-size: 14px;
    margin-bottom: 0.25rem;
    font-weight: bold;
  }
}
.OptionButton {
  border: none;
  background-color: #e4e5e6;
  font-size: 14px;
  color: #303533;
  margin-right: 5px;
  margin-bottom: 5px;
  &:hover {
    background-color: #cfcfcf;
  }
  &--active {
    background-color: #d8ebf9;
    &:hover {
      background-color: #d8ebf9;
    }
  }
}

.ButtonRow {
  display: flex;
  margin-top: 0.5rem;
  @media screen and (max-width: 565px) {
    flex-wrap: wrap;
    justify-content: center;
  }
}
