.FilterForm {
  background-color: #fff;
  border-bottom: 1px solid #eee;
  &__form {
    text-align: left;
    padding: 25px;
    position: relative;
  }
}

.TextInput {
  text-align: left;
  display: inline-block;
  margin: 0 1rem 0 0;
  width: 100%;
  input {
    width: 100%;
    color: #595a5b;
  }
}

.DropDown {
  display: flex;
  text-align: left;
  display: inline-block;
  margin: 0 1rem 0 0;
  width: 100%;
  select {
    cursor: pointer;
  }
  button {
    width: 100%;
    text-align: left;
    background: white;
  }
}

.ProductFilter {
  border: 1px solid;
  border-color: #ccc;
  border-radius: 3px;
  height: 35px;
  padding: 1rem;
  width: 100%;
}

.FilterFormButton {
  align-self: flex-end;
  margin-bottom: 4px;
}
