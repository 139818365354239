#share-subscribe-export-menu {
  background: #fff;
  box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, 0.35);
  left: -100px;
  position: absolute;
  top: 80px;
  transform: translate(-50%, -50%);
  width: 180px;
  z-index: 1000;

  @media (max-width: 1130px) {
    left: unset;
    position: fixed;
    right: -42px;
    top: 210px;
  }

  [data-reach-menu-item] {
    padding: 8px 12px;
  }

  [data-selected] {
    background: rgb(241, 241, 241);
  }
}
