.Table {
  width: 100%;
  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 0.75rem;
    color: #646464;
    cursor: pointer;
    &:hover {
      color: #363636;
    }
    svg {
      margin-left: 7px;
    }
  }
  thead {
    border-bottom: 1px solid rgb(230, 230, 230);
  }
  a {
    display: inline-block; // Only the link text is clickable
    color: #317bbd;
    font-weight: bold;
    text-decoration: none;
    &:hover {
      color: rgb(36, 97, 151);
    }
  }
}

.TableRow {
  position: relative;
  &--active {
    background: rgb(247, 247, 247);
  }
  &:hover {
    background: rgb(247, 247, 247);
  }
  td {
    max-width: 400px;
    min-width: 100px;
    padding: 1.25rem 0rem;
    border-radius: 5px;
    padding-right: 0.25rem;
    word-wrap: break-word;
  }
  &__show-hidden-menu {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10px;
    position: absolute;
    transform: translate(-100%, -50%);
    box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, 0.25);
  }
}
