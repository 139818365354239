.MerchantNameContainer {
  position: relative;
}

.MerchantNameContainer:hover .FullName {
  opacity: 1;
  background: rgb(247, 247, 247);
  padding-bottom: 1rem;
  padding-right: 1rem;
  border-radius: 10px;
}

.FullName {
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.05s ease;
}
