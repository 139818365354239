.glowing {
  animation-direction: alternate;
  animation-duration: 0.35s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: glowing;
}
@keyframes glowing {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
