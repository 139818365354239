.LoadingDots {
  display: flex;
  padding: 5px 0;
  .Dot {
    height: 7px;
    width: 7px;
    background: #327bbc;
    margin-right: 0.35rem;
    border-radius: 50%;
    @for $i from 1 through 3 {
      &:nth-of-type(#{$i}) {
        animation-name: animate;
        animation-delay: #{$i * 0.25}s;
        animation-duration: 0.55s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: ease;
        animation-direction: alternate;
      }
    }
  }
}

@keyframes animate {
  100% {
    background: #dbebf7;
  }
}
