.ExpandablePanelButton {
  align-items: center;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  min-height: 30px;
  min-width: 30px;
  margin-right: 0.5rem;
  &--gray {
    background-color: #808284;
    &:hover {
      background-color: #6f7174;
    }
  }
  &--primary {
    background-color: #007bc3;
    border-radius: 15px;
    padding: 0.15rem 0.75rem;
    min-width: 40px;
    &:hover {
      background-color: #004e7c;
      border-color: #004e7c;
    }
  }
  &__sign {
    font-size: 22px;
    font-weight: bolder;
  }
  &__count {
    margin-left: 0.15rem;
    font-weight: bolder;
  }
  &__buttonText {
    font-size: 0px;
  }
}
