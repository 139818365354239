.TooltipButtonContainer {
  display: flex;
}

.TooltipButton {
  align-items: center;
  display: flex;
  font-size: 0;
  height: 30px;
  justify-content: center;
  margin: 0 0.25rem;
  position: relative;
  width: 30px;
  z-index: 10;
}
.TooltipContainer {
  position: absolute;
  box-sizing: content-box;
  width: max-content;
}
.Tooltip {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  color: #fff;
  display: none;
  font-weight: bold;
  padding: 10px;
  position: relative;
  z-index: 100;

  &--origin-top {
    bottom: 40px;
    display: block;
  }
  &--origin-right {
    display: block;
    right: -40px;
    top: -2px;
  }
  &--origin-bottom {
    display: block;
    top: 40px;
  }
  &--origin-left {
    display: block;
    right: calc(100% + 5px);
    top: -2px;
  }
}
.Arrow {
  height: 0;
  position: absolute;
  width: 0;

  &--origin-top {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid rgba(0, 0, 0, 0.6);
    bottom: -6px;
  }
  &--origin-right {
    border-bottom: 6px solid transparent;
    border-right: 6px solid rgba(0, 0, 0, 0.6);
    border-top: 6px solid transparent;
    left: -3px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  &--origin-bottom {
    border-bottom: 6px solid rgba(0, 0, 0, 0.6);
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    top: -6px;
  }
  &--origin-left {
    border-bottom: 6px solid transparent;
    border-left: 6px solid rgba(0, 0, 0, 0.6);
    border-top: 6px solid transparent;
    right: -9px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
