.FloatingMenu {
  background: #fff;
  box-shadow: 0 0.25rem 1rem rgba(48, 55, 66, 0.25);
  border-radius: 2px;
  position: fixed;

  position: absolute;
  right: unset;
  left: 101%;
  top: 80px;

  @media (max-width: 1130px) {
    position: fixed;
    top: 210px;
    right: 4px;
    left: unset;
  }
  z-index: 100;
}
