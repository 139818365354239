.DataExplorerList {
  align-items: flex-start;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
  ul {
    list-style-type: none;
    padding: 0;
    width: 100%;
    li {
      align-items: flex-start;
      border: 1px solid rgb(231, 231, 231);
      display: flex;
      flex-direction: column;
      margin: 1rem 0;
      padding: 1.15rem;
      text-align: left;
      a {
        color: #317bbd;
        font-weight: bold;
        text-decoration: none;
        font-size: 1.05rem;
        padding-bottom: 0.25rem;
        &:hover {
          color: rgb(36, 97, 151);
        }
      }
    }
  }
  &__filters {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding: 1.25rem 1rem;
    label {
      text-align: left;
    }
  }
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
    width: 100%;
  }
  &__pagination {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 1rem;
  }
  hr {
    border-top: 1px solid #ccc;
    width: 100%;
  }
}
