.CategoryMultiselectContainer {
  display: flex;
  display: inline-block;
  margin: 0 1rem 0 0;
  text-align: left;
  width: 100%;
  select {
    cursor: pointer;
  }
  &__indent-guide {
    background: rgb(233, 233, 233);
    height: 100%;
    margin-left: 4px;
    margin-right: 8px;
    width: 2px;
  }
}
