.ColumnarContainer {
  background: #f4f4f4;
  display: flex;
  position: relative;
}
.ColumnarGrid {
  position: relative;
  border-bottom: 1px solid #e2e2e2;
}
.GridRow {
  display: flex;
  list-style-type: none;
  padding: 0;
  background-color: #fff;
  &:nth-child(even) {
    background: #f4f4f4;
  }
  & .Cell:last-child {
    border-right: 1px solid #e2e2e2;
  }
}

.GridHeader {
  background: #f4f4f4;
  border: none;
  color: #303533;
  font-weight: bold;
  position: sticky;
  text-transform: capitalize;
  top: 0;
}

.Cell {
  border-left: 1px solid #e2e2e2;
  display: flex;
  min-height: 50px;
  padding: 0.5rem 1rem;
  text-align: left;
  &--header {
    padding: 0 1rem;
    align-items: center;
    background: #f4f4f4;
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: right 20px center;
    display: flex;
    height: 45px;
    justify-content: flex-start;
    border-bottom: 1px solid #e2e2e2;
  }

  &--desc {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path style="fill: gray;" d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>');
  }
  &--asc {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path style="fill: gray; transform: rotate(180deg); transform-origin:center;" d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>');
  }
  &--dragging {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.15), 0 15px 12px rgba(0, 0, 0, 0.08);
  }
}

.Cell--header.Cell--active {
  background-color: #ebebeb;
}
.DraggableIcon {
  margin-right: 0.75rem;
}

.WindowContainer {
  position: relative;
  &--shadow::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 40px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.1),
      rgba(0, 0, 0, 0.15)
    );
    pointer-events: none;
  }
  & .Window {
    overflow: scroll;
  }
}
