.Modal {
  background-color: rgba(48, 53, 51, 0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 1000;
}

.ModalCard {
  align-items: center;
  background: #fff;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  max-height: 90vh;

  &__exit-btn {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='17' height='17' xmlns='http://www.w3.org/2000/svg' fill='%23808284'%3E %3Cpath d='M9.466 8l6.066 6.241a.506.506 0 0 1 0 .725l-.767.789a.478.478 0 0 1-.352.158.478.478 0 0 1-.353-.158L8.037 9.558l-6.024 6.197a.478.478 0 0 1-.353.158.478.478 0 0 1-.352-.158l-.767-.788a.506.506 0 0 1 0-.726L6.608 8 .542 1.759a.506.506 0 0 1 0-.725l.766-.789A.478.478 0 0 1 1.66.087c.133 0 .25.053.353.158l6.024 6.197L14.06.245a.478.478 0 0 1 .352-.158c.133 0 .25.053.353.158l.766.788a.506.506 0 0 1 0 .726L9.466 8z' fill-rule='evenodd'/%3E %3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    font-size: 0px;
    height: 40px;
    position: absolute;
    right: 15px;
    width: 40px;
  }
}
