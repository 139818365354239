@charset "UTF-8";
.Pagination {
  display: flex;
  align-items: center; }

.PageButton {
  padding: 0.25rem 0.75rem;
  margin: 0.5rem 0.1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600; }
  .PageButton--next::after {
    content: '»';
    font-size: 16px;
    padding-left: 0.2rem; }
  .PageButton--previous::before {
    content: '«';
    padding-right: 0.2rem;
    font-size: 16px; }

.elipsis {
  margin: 0.5rem 0.5rem; }
