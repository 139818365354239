.FetchSelectContainer {
  display: flex;
  display: inline-block;
  margin: 0 1rem 0 0;
  text-align: left;
  width: 100%;
  select {
    cursor: pointer;
  }
}
