.FilterGrid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  z-index: 100 !important;
  &--expanded {
    background: #fff;
    box-shadow: 0 4px 18px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    left: 0;
    padding: 25px;
    position: absolute;
    top: 0;
    z-index: 1;
  }
}
