.DatePicker {
  text-align: left;
  display: inline-block;
  min-width: 100%;
  margin: 0 1rem 0 0;
  select {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__button {
    text-align: left;
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #595a5b;
    &--blue {
      background-color: #007bc3;
      color: #fff;
    }
    &--transparent {
      background: none;
      border: none;
      color: #007bc3;
    }
  }
  &__container {
    background: #fff;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 10px 1px;
    margin-top: 5px;
    padding: 20px;
    position: absolute;
    z-index: 1;
    text-align: left;
    border-radius: 3px;
    .exit-button {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 0;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='17' height='17' xmlns='http://www.w3.org/2000/svg' fill='%23808284'%3E %3Cpath d='M9.466 8l6.066 6.241a.506.506 0 0 1 0 .725l-.767.789a.478.478 0 0 1-.352.158.478.478 0 0 1-.353-.158L8.037 9.558l-6.024 6.197a.478.478 0 0 1-.353.158.478.478 0 0 1-.352-.158l-.767-.788a.506.506 0 0 1 0-.726L6.608 8 .542 1.759a.506.506 0 0 1 0-.725l.766-.789A.478.478 0 0 1 1.66.087c.133 0 .25.053.353.158l6.024 6.197L14.06.245a.478.478 0 0 1 .352-.158c.133 0 .25.053.353.158l.766.788a.506.506 0 0 1 0 .726L9.466 8z' fill-rule='evenodd'/%3E %3C/svg%3E");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 20px;
      height: 40px;
      width: 40px;
    }
  }
}
.DatePickerWrapper {
  margin-left: 5px;
  position: absolute;
}
.DatePicker__Container {
  background: #fff;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 10px 1px;
  margin-top: 5px;
  padding: 20px;
  position: absolute;
  z-index: 1;
  text-align: left;
  border-radius: 3px;
}

.DateSelect {
  background: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  display: flex;
  color: #555;
  min-width: 245px;
  border-radius: 3px;
  position: relative;

  &__preset {
    color: #333;
    width: 100%;
    background: #fff;
    padding: 10px;
    border: 1px solid #ccc;

    height: 30px;
    border-radius: 1px;
    background: #fff;
    border-color: #ccc;
    color: #555;
  }
  &__icon {
    overflow: hidden;
    display: block;
    height: 10px;
    left: 88%;
    position: absolute;
    top: 35%;
    color: #ccc;
  }
}

// NOTE: the order of these styles DO matter

.DayPicker {
  box-shadow: none !important;
  border-radius: 0%;
  min-height: 294px;
}

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  // background: #82e0aa; //background
  background: #eee !important;
  color: #333 !important; //text
  border: 1px solid #fff !important; //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: #007bc3 !important;
  color: white !important;
  border-color: #007bc3 !important;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  color: white;
  border-color: #007bc3 !important;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #eee !important;
  border-color: #e4e7e7 !important;
  color: #007bc3 !important;
}
.DateInput_input__focused {
  border-bottom: 2px solid #007bc3 !important;
}
.DateRangePickerInput {
  display: flex !important;
  justify-content: space-around;
  align-items: center;
}
.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 0px 0 11px;
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #007bc3 !important;
}

.DateInput {
  padding: 0 !important;
  display: block !important;
  width: 100px !important;
}
.DateInput_input {
  text-align: center;
  font-weight: normal !important;
  font-size: 16px !important;
  width: 100% !important;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 0.25rem 0 !important;
  border: 0;
  border-top: 2px solid transparent !important;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
